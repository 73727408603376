import { PrivacyPolicyDocumentSigned } from './exam'
import { InternalPatient } from './patient'
import { TypeCommons } from './types'

export enum DOCUMENT_STATUS {
	SIGNED = 'SIGNED',
	REVOKED = 'REVOKED',
	EXPIRED = 'EXPIRED',
}

export enum DOCUMENT_SIGNED_BY {
	PATIENT = 'PATIENT',
	PARENT = 'PARENT',
	GUARDIAN = 'GUARDIAN',
}

export type PrivacyPolicyDocumentSignedV2 = TypeCommons &
	PrivacyPolicyDocumentSigned & {
		status: DOCUMENT_STATUS
		patient: InternalPatient
		signedBy: DOCUMENT_SIGNED_BY
		signerFullName?: string
		kinship?: string
		note?: string
	}

export type CreatePPDV2 = Partial<PrivacyPolicyDocumentSignedV2> & {
	storeId: string
	signatureImg: string
}

export type CreatePPDV2Manual = Omit<CreatePPDV2, 'signatureImg'> & {
	fileContent: string
}
