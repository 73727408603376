import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { isValid as isValidDate } from 'date-fns'

import { TeloDatePickerProps } from '../../components/CustomDatePicker/TeloDatePicker'
import { FieldProps } from '../../model/types'
import FormattedDatePicker from '../FormattedDatePicker'
import theme from '../theme'
import { pxToRem } from '../../libs/style'
import { InputHelperTextWithIcon } from './InputHelperTextWithIcon'
import { capitalizeFirstLetter } from '../../libs/utils'

type Props = TeloDatePickerProps &
	FieldProps & {
		preventSetFieldValue?: boolean
		onSelectedDate(date: Date): void
		showErrorOnlyIfTouched?: boolean
		clearValue: boolean
	}

const BaseDatePickerStyled = styled(FormattedDatePicker)<{
	disabled?: boolean
}>`
	& label {
		font-family: ${theme.typography.fontFamily};
		color: ${theme.palette.greyCustom.main};
	}

	& fieldset {
		border-radius: 12px;
		border-color: ${theme.palette.grey[700]};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: ${theme.palette.secondary.main};
	}

	.MuiInputBase-root {
		border-radius: ${pxToRem(12)}rem;
		z-index: 0;
	}

	.MuiPopper-root.MuiPickersPopper-root {
		z-index: 2000 !important;
	}

	${({ disabled }) =>
		disabled &&
		`
		fieldset {
			border: 0;
		}

		& input {
			z-index: ${theme.zIndex.tooltip};
		}
		
		& button {
			z-index: ${theme.zIndex.tooltip};
			z-index: 999999;
		}
	`}
`

const DatePicker: React.FC<Props> = ({
	clearValue = false,
	field,
	form,
	label,
	onSelectedDate,
	preventSetFieldValue = false,
	showErrorOnlyIfTouched = true,
	initialDate,
	minDate,
	format,
	...props
}) => {
	const { t } = useTranslation()
	const fieldTouched = get(form.touched, field.name)
	const fieldError = get(form.errors, field.name)
	const showError: boolean = showErrorOnlyIfTouched
		? !!fieldError && fieldTouched
		: !!fieldError

	return (
		<BaseDatePickerStyled
			{...props}
			label={label}
			name={field.name}
			minDate={minDate}
            format={format}
			isError={showError}
			onBlur={field.onBlur}
			helperText={
				props.helperText && !fieldError && !fieldTouched ? (
					props.helperText
				) : showError ? (
					<InputHelperTextWithIcon>
						{capitalizeFirstLetter(
							t(
								typeof fieldError === 'string'
									? fieldError
									: JSON.stringify(fieldError),
							),
						)}
					</InputHelperTextWithIcon>
				) : undefined
			}
			onChange={date => {
				if (date && isValidDate(date)) {
					const offset = date.getTimezoneOffset()
					const offsetInHour = Math.abs(offset / 60)
					date.setHours(date.getHours() + offsetInHour)

					if (preventSetFieldValue === false) {
						form.setFieldValue(field.name, date)
					}
					onSelectedDate && onSelectedDate(date)
				} else {
					if (clearValue === true && date === null) {
						form.setFieldValue(field.name, undefined)
						return
					}
					form.setFieldValue(field.name, date) // Set the invalid value to trigger Field validation
				}
			}}
		/>
	)
}

export default DatePicker
