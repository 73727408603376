import i18n from 'i18next'
import { ca, enUS, es, fr, it } from 'date-fns/locale'

export const formatName = ({
	name,
	surname,
	middleName,
}: {
	name: string
	surname: string
	middleName?: string
}): string => {
	return `${name} ${middleName ? `${middleName} ` : ''}${surname}`
}

export const formatAddress = ({
	address,
	postalCode,
	province,
	city,
}: {
	address?: string
	postalCode?: string
	province?: string
	city?: string
}): string => {
	const addressParts = []
	if (address) {
		addressParts.push(address)
	}
	if (postalCode) {
		addressParts.push(postalCode)
	}
	if (province) {
		addressParts.push(province)
	}
	if (city) {
		addressParts.push(city)
	}
	return addressParts.join(', ')
}

export const getDateFnsLocale = () => {
	const locale = i18n.language
	switch (locale) {
		case 'ca':
		case 'ca-ES':
			return ca
		case 'es':
		case 'es-ES':
			return es
		case 'fr':
		case 'fr-FR':
			return fr
		case 'it':
		case 'it-IT':
			return it
		default:
			return enUS
	}
}
