import React from 'react'
import { useDateFormatter } from '../hooks/useDateFormatter'
import DatePicker, { DatePickerProps } from './DatePicker'

export type FormattedDatePickerProps = Omit<DatePickerProps, 'lang'> & {
    format?: string
}

const FormattedDatePicker: React.FC<FormattedDatePickerProps> = (props) => {
    const { format, ...rest } = props
    const { dateFormat } = useDateFormatter()

    return <DatePicker {...rest} format={format || dateFormat} />
}

export default FormattedDatePicker